var db = firebase.firestore();



/*firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      console.log(user.uid);
      
     
      var docRef = db.collection('users').doc(user.uid);  
      db.collection('users').doc(user.uid).get();
      console.log("Document data-1:", doc.data())
      .then(function(doc) {
          console.log("Document data-2:", doc.data());
          if (doc.exists) {
              console.log("Document data-3:", doc.data());
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
    } else {
        window.location = 'login.html';// User not logged in or has just logged out.
    }
  });*/

  //TODO hazem's snippet to get Data based on current user id (matching doc id)
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User logged in already or has just logged in.
      console.log(user.uid);
      
     
      var docRef = db.collection('users').doc(user.uid);  
      docRef.get().then(doc => {
          if (doc.exists) {
              console.log("Document data:", doc.data());
              const userData = doc.data();
              const detailsForm = document.querySelector('#detailsForm');
              document.getElementById('userName').value = userData.userName || "";
              document.getElementById('founderName').innerHTML = userData.userName || "Founder";
              document.getElementById('startupName').value = userData.startupName || "";
              document.getElementById('startupURL').value = userData.startupURL || "";
              document.getElementById('userPhone').value = userData.userPhone || "";
              document.getElementById('startupStage').value = userData.startupStage || "";
              document.getElementById('targetMarket').value = userData.targetMarket || "";
              document.getElementById('countryName').value = userData.countryName || "";

              const productForm = document.querySelector('#productForm');
              document.getElementById('problemDescription').value = userData.problemDescription || "";
              document.getElementById('solutionDescription').value = userData.solutionDescription || "";
              document.getElementById('startupDescription').value = userData.startupDescription || "";
              document.getElementById('startupInnovation').value = userData.startupInnovation || "";
              document.getElementById('startupMotivation').value = userData.startupMotivation || "";
              document.getElementById('startupLaunch').value = userData.startupLaunch || "";
            
              const marketForm = document.querySelector('#marketForm');
              document.getElementById('marketGrowth').value = userData.marketGrowth || "";
              document.getElementById('marketShare').value = userData.marketShare || "";
              document.getElementById('marketCompetition').value = userData.marketCompetition || "";
              document.getElementById('businessModel').value = userData.businessModel || "";
              document.getElementById('marketSize').value = userData.marketSize || "";

              const milesForm = document.querySelector('#milesForm');
              document.getElementById('startupFounders').value = userData.startupFounders || "";
              document.getElementById('teamSize').value = userData.teamSize || "";
              document.getElementById('startupRevenue').value = userData.startupRevenue || "";
              document.getElementById('startupUsers').value = userData.startupUsers || "";
              document.getElementById('startupFunding').value = userData.startupFunding || "";
              document.getElementById('startupIncorporation').value = userData.startupIncorporation || "";
           
             //Verify if user has confirmed application to programs
             const confirmEv2019 = document.querySelector('#confirmEv2019');
             const confirmF619 = document.querySelector('#confirmF619');
             var hasConfirmedEv2019 = userData.confirmedEv2019;
             var hasConfirmedF619 = userData.confirmedF619;
                
                 if (hasConfirmedEv2019 === true){
                        document.getElementById('confirmEv2019').innerHTML = 'Application Confirmed';
                        document.getElementById('confirmEv2019').style.pointerEvents="none";
                        document.getElementById('confirmEv2019').style.cursor="default";
                        document.getElementById('confirmEv2019').style.background="grey";
                     console.log('[INFO] Ev2019 is True and Inner text has changed');
                        } else {
                    // doc.data() will be undefined in this case
                    console.log("[INFO] Ev2019 is false!");
                        }
                 if (hasConfirmedF619 === true){
                     document.getElementById('confirmF619').innerHTML = 'Application Confirmed';
                     document.getElementById('confirmF619').style.pointerEvents="none";
                        document.getElementById('confirmF619').style.cursor="default";
                        document.getElementById('confirmF619').style.background="grey";
                     console.log('[INFO] F619 is True and Inner text has changed');
                    }   else {
                    // doc.data() will be undefined in this case
                    console.log("[INFO] F619 is false!");
                        }
        

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
      } else {
          window.location = 'login.html';// User not logged in or has just logged out.
      }
    });